import React from 'react';
import { Field } from 'formik';
import classnames from "classnames";
import "./forms.scss";

const TextArea = (props) => {
    const { label, name, required, rows, maxLength, ...rest } = props;

    const ifRequired = () => {
        if (required == true) {
            return <span className="required">*</span>
        }
    }
    const displayedRows = rows || "4";
    const length = maxLength || "";
    return (
        <Field name={name} {...rest} >
            {({ field, meta }) => (
                <div className={classnames("partie-form__field-container", {
                    'partie-form__field-container--error': meta.error && meta.touched
                })}>
                    <label className="partie-form__label" htmlFor={name}>
                        {label} {ifRequired()}
                    </label>
                    <textarea
                        className="partie-form__field"
                        id={name}
                        type="text"
                        onChange={field.onChange}
                        rows={displayedRows}
                        maxLength={length}
                    />
                    {(meta.error && meta.touched) && (
                        <span className="partie-form__field-caption">{meta.error}</span>
                    )}
                </div>
            )}
        </Field>
    );
};

export default TextArea;